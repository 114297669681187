import React, { PropsWithChildren } from "react";
import { activeTabCTX } from "./activeTab.context";
type Props = {};

const ActiveTabProvider = (props: PropsWithChildren) => {
  const [activeTab, setActiveTab] = React.useState<number>(1);

  return (
    <activeTabCTX.Provider
      value={{
        activeTab,
        setActiveTab,
      }}>
      {props.children}
    </activeTabCTX.Provider>
  );
};

export default ActiveTabProvider;
