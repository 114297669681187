import { Chain } from "@usedapp/core";

export const Bitcoin: Chain = {
  chainId: 18332,
  chainName: "BTC Testnet",
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: "0x0000000000000000000000000000000000000000",
  getExplorerAddressLink: (address: string) =>
    `https://blockchair.com/bitcoin/testnet${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://blockchair.com/bitcoin/testnet${transactionHash}`,

  rpcUrl: "https://api.blockchair.com/bitcoin/testnet/stats",
  blockExplorerUrl: "https://blockchair.com/bitcoin/testnet",
  nativeCurrency: {
    name: "Bitcoin Testnet",
    symbol: "BTC",
    decimals: 8,
  },
};
