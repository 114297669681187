import React, { createContext } from "react";

type ActiveTabType = {
    activeTab?: number;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
};
export const activeTabCTX = createContext<ActiveTabType>({
    activeTab: 1,
    setActiveTab: () => { },
});
