import React from "react";
import { deadlineContext } from "./deadline.context";
type Props = React.PropsWithChildren;

const DeadlineProvider = (props: Props) => {
  const [deadline, setDeadline] = React.useState<number>(30);
  return (
    <deadlineContext.Provider
      value={{
        deadline,
        setDeadline,
      }}>
      {props.children}
    </deadlineContext.Provider>
  );
};

export default DeadlineProvider;
