import React from "react";
import { poolsContext } from "./pools.context";
import { onPoolsLoaded$ } from "./pools.signal";
import { useSignal } from "../../hooks";

type PoolsProviderProps = {
  onPoolsLoaded?: () => void;
} & React.PropsWithChildren;

export function PoolsProvider(props: PoolsProviderProps) {
  const [pools, setPools] = React.useState<any[]>([]);
  useSignal(onPoolsLoaded$, {
    next: (pools) => {
      console.log(pools);
      setPools(pools);
      if (props.onPoolsLoaded) props.onPoolsLoaded();
    },
  });

  return (
    <poolsContext.Provider value={pools}>
      {props.children}
    </poolsContext.Provider>
  );
}
