import { ThemeProvider, createTheme } from "@mui/material";
import { blue, indigo } from "@mui/material/colors";
import { DAppProvider } from "@usedapp/core";
import { useState } from "react";
import { AppRouter } from "./app/app.router";
import Intro from "./features/Intro/Intro.feature";
import TopNav from "./features/TopNav/TopNav.feature";
import { RootLayout } from "./layouts";
import { dappConf } from "./shared/config/dapp.confing";
import { PoolsProvider } from "./states/pools/pools.provider";
import { AlertProvider } from "./states/alert/alert.provider";
import { XDEFIProvider } from "./states/xdefi/xdefi.provider";
import { wagmiConfig } from "./shared/config/wagmi.config";
import ActiveTabProvider from "./states/ActiveTab/activeTab.provider";
import DeadlineProvider from "./states/deadline/deadline.provider";
import { WagmiConfig } from "wagmi";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: blue,
    info: {
      main: "#FFF",
    },
  },
});

function AppProvider() {
  const [unloadIntro, setUnloadIntro] = useState(true);
  const [poolsLoaded, setPoolsLoaded] = useState(false);
  const [shouldBeDisabled, setShouldBeDisabled] = useState(false);
  // const hasMetaMask = (window as any).ethereum?.isMetaMask || false;

  return (
    <>
      <ThemeProvider theme={theme}>
        <XDEFIProvider>
          <ActiveTabProvider>
            <DeadlineProvider>
              <RootLayout>
                {/* @ts-ignore */}
                <WagmiConfig config={wagmiConfig}>
                  <DAppProvider config={dappConf}>
                    <PoolsProvider
                      onPoolsLoaded={() => {
                        console.log("pools loaded");
                        setPoolsLoaded(true);
                      }}>
                      <AlertProvider>
                        <AppRouter />
                      </AlertProvider>
                    </PoolsProvider>
                  </DAppProvider>
                </WagmiConfig>
              </RootLayout>
            </DeadlineProvider>
          </ActiveTabProvider>
        </XDEFIProvider>
      </ThemeProvider>
    </>
  );
}

export default AppProvider;
