import Web3 from "web3";

import { getFactoryInfo } from "../tools/getFactoryInfo";
import { getRouterInfo } from "../tools/getRouterInfo";
import { getPairs } from "./getPairs";

export const getPools = async (providerUrl: any, routerAddr: any, networkIndex: number, networkList: any[]) => {
  if (networkIndex == 1) {
    const provider = new Web3.providers.HttpProvider(providerUrl);
    const w3 = new Web3(provider);
    const factoryInfo = await getPairs(networkList[networkIndex].factoryAddr, providerUrl, w3);

    return factoryInfo?.allPairs;


  } else {
    const provider = new Web3.providers.HttpProvider(providerUrl);
    const w3 = new Web3(provider);

    const routerInfo = await getRouterInfo(routerAddr, w3);
    const factoryInfo = await getFactoryInfo(routerInfo.factory, w3);

    return factoryInfo.pairsInfo;

  }

};
