import { factoryAbi } from "../contracts/abis";
import Web3 from "web3";
import { getPairsInfo } from "./getPairsInfo";

export async function getFactoryInfo(factoryAddr: any, web3: Web3) {
  const factory = new web3.eth.Contract(factoryAbi, factoryAddr);

  const response = {
    fee: await factory.methods.feeTo().call(),
    feeToSetter: await factory.methods.feeToSetter().call(),
    allPairsCount: (await factory.methods.allPairsLength().call()) as number,
    allPairs: [] as any[],
    pairsInfo: null as any,
  };

  for (let index = 0; index < response.allPairsCount; index++) {
    response.allPairs[index] = await (factory.methods.allPairs as any)(
      index
    ).call();
  }

  response.pairsInfo = await getPairsInfo(response.allPairs, web3);

  return response;
}