import { useEffect } from "react";
import { Observer, Observable } from "rxjs";

export function useSignal<T>(
  observabel: Observable<T>,
  observer: Partial<Observer<T>>,
  ...tearDowns: (() => void)[]
) {
  useEffect(() => {
    const sub = observabel.subscribe({
      next: (value) => {
        if (observer?.next) observer.next(value);
      },
      error: (value) => {
        if (observer?.error) observer.error(value);
      },
      complete: () => {
        if (observer?.complete) observer.complete();
      },
    });

    sub.add(() => {
      if (!tearDowns.length) return;
      for (const tearDown of tearDowns) {
        try {
          tearDown();
        } catch (e) {
          break;
        }
      }
    });

    return () => sub.unsubscribe();
  });
}
