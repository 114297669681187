"use client";

import { tokenContract } from "../contracts/providers";

import {
  useContractFunction,
  useEthers,
  useTokenAllowance,
} from "@usedapp/core";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { getSigner } from "../tools/getSigner";
import { ICryptoModel } from "../interfaces/ICrypto.model";
import { GOERLI_SWAP_ADDR } from "../shared/config/dapp.confing";
import { useAccount } from "wagmi";

export function useApproval(
  token: ICryptoModel | undefined,
  amount: string = "0"
) {
  const [isApproved, setIsApproved] = useState(false);
  // const { account } = useEthers();
  const { address: account } = useAccount()

  const tokenApproval = useContractFunction(
    tokenContract(token?.address || "", getSigner(account || "")) as any,
    "approve",
    {
      transactionName: "onApproveRequested",
      gasLimitBufferPercentage: 10,
    }
  );

  const allowance = useTokenAllowance(
    token?.address || "",
    account,
    GOERLI_SWAP_ADDR
  );

  useEffect(() => {
    if (!allowance) {
      setIsApproved(false);
      return;
    }
    const isAllowed = allowance?.gt(ethers.utils.parseUnits(amount)) || false;
    setIsApproved(isAllowed);
  }, [allowance]);

  return {
    approve: () =>
      tokenApproval
        .send(GOERLI_SWAP_ADDR, ethers.constants.MaxUint256, {
          gasLimit: 538560,
        })
        .then((response) => {
          if (!response) return response;
          setIsApproved(true);
          return response;
        }),
    isApproved,
  };
}
export default useApproval;
