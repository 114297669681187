export class CacheService {
  constructor(private token: string) {}

  storeData(data: Object, expire?: Date) {
    if (!expire) {
      expire = new Date();
      expire.setHours(expire.getHours() + 1);
    }

    window.localStorage.setItem(
      this.token,
      JSON.stringify({
        data,
        exp: expire.getTime(),
      })
    );
  }

  readData() {
    const { exp, data } = JSON.parse(
      window.localStorage.getItem(this.token) || "{}"
    );
    if (!data || !exp) {
      return undefined;
    }

    if (Date.now() >= exp) {
      window.localStorage.removeItem(this.token);
      return undefined;
    }

    return data;
  }
}
