import { Chain } from "@usedapp/core";

export const ZetaChain: Chain = {
  chainId: 7001,
  chainName: "ZetaChain Athens 3",
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: "0x0000000000000000000000000000000000000000",
  getExplorerAddressLink: (address: string) =>
    `https://athens3.explorer.zetachain.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) =>
    `https://athens3.explorer.zetachain.com/tx/${transactionHash}`,

  rpcUrl: "https://zetachain-athens-evm.blockpi.network/v1/rpc/public",
  // "https://rpc.ankr.com/zetachain_evm_athens_testnet/022f83e779d7fdab81b19a0e3ad2a448e3a3952dd8b3397901f3eb2caa5bdd1b",
  blockExplorerUrl: "https://athens3.explorer.zetachain.com/",
  nativeCurrency: {
    name: "Athens 3",
    symbol: "aZETA",
    decimals: 18,
  },
};
