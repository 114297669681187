import { erc20Abi } from "../contracts/abis";
import pairsAbi from "../contracts/abis/pair.json";
import Web3 from "web3";

export async function getPairsInfo(pairAddrs: string[], web3: Web3) {
  const pairsInfo = [];

  for (const pairAddr of pairAddrs) {
    const pair = new web3.eth.Contract(pairsAbi, pairAddr);

    const token0Addr = await pair.methods.token0().call();
    const token1Addr = await pair.methods.token1().call();

    const token0Contract = new web3.eth.Contract(erc20Abi, token0Addr as any);
    const token1Contract = new web3.eth.Contract(erc20Abi, token1Addr as any);

    const token0Name = await token0Contract.methods.name().call();
    const token1Name = await token1Contract.methods.name().call();

    const token1Symbol = await token1Contract.methods.symbol().call();
    const token0Symbol = await token0Contract.methods.symbol().call();

    pairsInfo.push({
      address: pairAddr,
      token0Addr,
      token1Addr,
      token0Name,
      token1Name,
      token1Symbol,
      token0Symbol,
    });
  }


  return pairsInfo;
}
