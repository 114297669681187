import { BSCTestnet, Goerli, Mumbai } from "@usedapp/core";
import { Bitcoin } from "../chains/bitcoin.chain";
import { ZetaChain } from "../chains/zeta.chain";
import { INetworkModel } from "../../interfaces/INetwork.model";
import { ROUTER_ADDR } from "../config/dapp.confing";

export const Networks: INetworkModel[] = [
  {
    networkName: "ETH Goerli",
    chainId: Goerli.chainId,
    zetaChainID: "goerli_testnet", //`0x${Goerli.chainId}`,
    iconUrl: "/networks/ETH.svg",
    nativeCoinSymbol: "ETH",
    routerAddr: ROUTER_ADDR,
    scan: "https://goerli.etherscan.io/address/",
    zrc20TokenAddress: "0x13A0c5930C028511Dc02665E7285134B6d11A5f4",
    coins: [
      {
        coinSymbol: "ETH",
        iconUrl: "/networks/ETH.svg",
        zrc20TokenAddress: "0x13A0c5930C028511Dc02665E7285134B6d11A5f4",
      }
    ]
  },
  {
    networkName: "ZetaChain",
    chainId: ZetaChain.chainId,
    iconUrl: "/networks/ZETA.svg",
    zetaChainID: "zeta_testnet", //`0x${ZetaChain.chainId}`,
    nativeCoinSymbol: "ZETA",
    routerAddr: "0x9fd96203f7b22bCF72d9DCb40ff98302376cE09c",
    factoryAddr: "0x2ca7d64A7EFE2D62A725E2B35Cf7230D6677FfEe",
    scan: "https://zetachain-athens-3.blockscout.com/address/",
    zrc20TokenAddress: "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
    coins: [
      {
        iconUrl: "/networks/ZETA.svg",
        coinSymbol: "ZETA",
        zrc20TokenAddress: "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
      },
      // {
      //   iconUrl: "/networks/ZETA.svg",
      //   coinSymbol: "WZETA",
      //   zrc20TokenAddress: "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
      // },
    ]
  },
  {
    networkName: Mumbai.chainName,
    chainId: Mumbai.chainId,
    iconUrl: "/networks/polygon.webp",
    zetaChainID: "mumbai_testnet", //`0x${Mumbai.chainId}`,
    nativeCoinSymbol: "MATIC",
    routerAddr: ROUTER_ADDR,
    scan: "https://mumbai.polygonscan.com/address/",
    zrc20TokenAddress: "0x48f80608B672DC30DC7e3dbBd0343c5F02C738Eb",
    coins: [
      {
        iconUrl: "/networks/polygon.webp",
        coinSymbol: "MATIC",
        zrc20TokenAddress: "0x48f80608B672DC30DC7e3dbBd0343c5F02C738Eb",
      }
    ]
  },
  {
    networkName: "BSC Testnet",
    chainId: BSCTestnet.chainId,
    iconUrl: "/networks/BNB.svg",
    zetaChainID: "bsc_testnet", //`0x${BSCTestnet.chainId}`,
    nativeCoinSymbol: "BNB",
    routerAddr: ROUTER_ADDR,
    scan: "https://testnet.bscscan.com/address/",
    zrc20TokenAddress: "0xd97B1de3619ed2c6BEb3860147E30cA8A7dC9891",
    coins: [
      {
        coinSymbol: "BNB",
        iconUrl: "/networks/BNB.svg",
        zrc20TokenAddress: "0xd97B1de3619ed2c6BEb3860147E30cA8A7dC9891"

      }
    ]
  },
  {
    networkName: Bitcoin.chainName,
    chainId: Bitcoin.chainId,
    iconUrl: "/networks/BTC.webp",
    nativeCoinSymbol: Bitcoin.nativeCurrency?.symbol || "BTC",
    routerAddr: ROUTER_ADDR,
    zetaChainID: `0x${Bitcoin.chainId}`,
    scan: "https://blockchair.com/bitcoin/testnet/address/",
    zrc20TokenAddress: "0x65a45c57636f9BcCeD4fe193A602008578BcA90b",
    coins: [
      {
        coinSymbol: "BTC",
        iconUrl: "/networks/BTC.webp",
        zrc20TokenAddress: "0x65a45c57636f9BcCeD4fe193A602008578BcA90b",
      }
    ]
  },
];
