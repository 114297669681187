import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Intro from "../features/Intro/Intro.feature";

const SwapPage = React.lazy(() =>
  import("./swap/swap.page").then((r) => {
    return {
      default: r.SwapPage,
    };
  })
);
const PollPage = React.lazy(() =>
  import("./pool/pool.page").then((r) => {
    return {
      default: r.PoolPage,
    };
  })
);
const router = createBrowserRouter([
  {
    path: "",
    element: <Navigate to={"./swap"} />,
  },
  {
    path: "swap",
    element: (
      <React.Suspense fallback={<Intro />}>
        <SwapPage />
      </React.Suspense>
    ),
  },
  {
    path: "pool",
    element: (
      <React.Suspense fallback={<Intro />}>
        <PollPage />
      </React.Suspense>
    ),
  },
]);
export function AppRouter() {
  return <RouterProvider router={router} />;
}
