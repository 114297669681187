import React from "react";
import { globalStates } from "../states/globalStates";

export function useGlobalState<T = any>(token: string) {
  try {
    return React.useContext<T>(globalStates[token]) || undefined;
  } catch {
    return undefined;
  }
}
