import React, { useState } from "react";
import { xdefiCtx as XdefiCtx } from "./xdefi.context";
import { useSignal } from "../../hooks";
import { connectXdefi$ } from "./xdefi.signal";
import axios from "axios";

export function XDEFIProvider(props: React.PropsWithChildren) {
  const [account, setAccount] = useState(undefined);
  useSignal(connectXdefi$, {
    next: async () => {
      const $xfi = (window as any)?.xfi;
      if (!$xfi) return;
      const acc = (await $xfi?.bitcoin?.getAccounts())?.[0];
      setAccount(acc);
      const apiKey = "cqt_rQFw6F3Hy3DTjyKwymwQVVh6b8my";
      axios
        .get(
          `https://api.covalenthq.com/v1/btc-testnet/address/${acc}/?key=${apiKey}`
        )
        .then((r) => {
          console.log(r.data.data.items[0]);
        });
    },
  });

  return (
    <XdefiCtx.Provider
      value={{
        account,
      }}
    >
      {props.children}
    </XdefiCtx.Provider>
  );
}
