import {
  BSCTestnet,
  Config,
  Connector,
  DEFAULT_SUPPORTED_CHAINS,
  Goerli,
  Mainnet,
  Mumbai,
} from "@usedapp/core";
import { ZetaChain } from "../chains/zeta.chain";
export const CROSS_CHAIN_ADDR = "0xc77AcaC25Dba61f019e38be3575BD19b07414e3d" //"0xDBf712f0Fc3af77753DC751664b92672Da7A2403" //"0x1F8D193548Da5E13990ea169aD1194950f79aa89" //"0x8D77203bB085b1a73FCE692E65D502Ef732f397B" //"0xed0a303B63E6BDa1fEef0049f2c5e3c680931dDf" //  "0x73572795B93fB7b6FC077B9E863dEE521ddB43C3" // "0xba231Ebd376fae6aBCaCa1422b379b8D4A696D8A" //"0xe2ea480e1F1E8A118A12d0FfBCe45603457Fa146" //"0xcA93886855021D96E7f80340C1Db182DEFBFE184" //"0x6592E0DeDf4A279fA8CCFb76993DD215d17296A1"; // "0x53bB6CCf9fD57A4179248Ed5D812000542746405"; //0xEE5Fbfdd9D30d7149fC27EC42B81b51513D4c314
export const ROUTER_ADDR = "0xABf63f63F0ee5F51b822b2e53412fC1C3EDBDf1A" //"0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"; //"0xFbe309220a90E444f5021268d785163809ca7553"; // "0xABf63f63F0ee5F51b822b2e53412fC1C3EDBDf1A";
// export const GOERLI_SWAP_ADDR = "0xa7498eAf74011B39DE257C85e9C201eA8ba8af68" //"0xF390c1dB2F4592e10CB937e36210caeD31f7c25C"//"0x2F67ec2eE0c1FA455B8A71bDB81C1fC5BA0D52eb"; // swap with fee
export const WALLET_CONNECT_ID = "c21bdebe7afd553256ed6fa16d756143"
export const GOERLI_SWAP_ADDR = "0x1cc2db0eAc1A7AC2475ff705F9683094fc05Be16"
export const dappConf: Config = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/119ab4a8bc9f4003a03eef14a501e7ce",
    [Goerli.chainId]:
      "https://goerli.infura.io/v3/119ab4a8bc9f4003a03eef14a501e7ce",
    [Mumbai.chainId]:
      "https://rpc.ankr.com/polygon_mumbai/022f83e779d7fdab81b19a0e3ad2a448e3a3952dd8b3397901f3eb2caa5bdd1b",
    [BSCTestnet.chainId]:
      "https://rpc.ankr.com/bsc_testnet_chapel/022f83e779d7fdab81b19a0e3ad2a448e3a3952dd8b3397901f3eb2caa5bdd1b",
    [ZetaChain.chainId]:
      "https://rpc.ankr.com/zetachain_evm_athens_testnet"
    // "https://rpc.ankr.com/zetachain_evm_athens_testnet/022f83e779d7fdab81b19a0e3ad2a448e3a3952dd8b3397901f3eb2caa5bdd1b",
  },
  networks: [...DEFAULT_SUPPORTED_CHAINS, ZetaChain, Mumbai],
};
