import { Contract } from "ethers";
import { factoryAbi, zetaFactoryAbi } from "../contracts/abis";
import { Provider } from "@ethersproject/abstract-provider";
import Web3 from "web3";

export async function getPairs(factoryAddr: any, providerUrl: Provider, w3?: Web3) {
    if (!w3) return
    const factory = new w3.eth.Contract(zetaFactoryAbi as any, factoryAddr);
    // const factory = new Contract(zetaFactoryAbi as any, factoryAddr, providerUrl);
    console.log(factory);

    const response: {
        allPairsCount: number
        allPairs: any[]
    } = {
        allPairsCount: (await factory.methods.allPairsLength().call()) as number,
        allPairs: []
    }
    for (let index = 0; index < response.allPairsCount; index++) {
        console.log(index);

        response.allPairs[index] = await (factory.methods.allPairs as any)(
            index
        ).call();
    }

    return response;
}
