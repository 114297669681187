import factory from "./factoryABI.json";
import router from "./routerABI.json";
import erc20 from "./erc20.json";
import swap from "./Swap.json";
import zuniswapV2Router from "./ZuniswapV2Router.json"
import zuniswapV2Factory from "./ZuniswapV2Factory.json"
import goerliSwap from "./goerli.json";
export { default as pairAbi } from "./pair.json";
export const factoryAbi = factory.abi;
export const routerAbi = router.abi;
export const erc20Abi = erc20.abi;
export const swapAbi = swap.abi;
export const zetaRouterAbi = zuniswapV2Router.abi
export const zetaFactoryAbi = zuniswapV2Factory.abi
export const goerliSwapAbi = goerliSwap.abi;
