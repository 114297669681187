"use client";
import { ethers } from "ethers";
import routerAbi from "./abis/routerABI.json";
import koaladexAbi from "./abis/koaladexABI.json";
import factoryAbi from "./abis/factoryABI.json";
import erc20Abi from "./abis/erc20.json";
import Web3 from "web3";
import { ROUTER_ADDR } from "../shared/config/dapp.confing";

function contractProvider(
  contract_address: string,
  abi_location: ethers.ContractInterface
) {
  return (signer: ethers.Signer | undefined) => {
    if (!contract_address) return undefined;
    return new ethers.Contract(contract_address, abi_location, signer);
  };
}

export const provider = new Web3.providers.HttpProvider(
  "https://goerli.infura.io/v3/119ab4a8bc9f4003a03eef14a501e7ce"
  // "goerli",
  // "HtKyeZzKPycnZQYj6lAtIu2jcxUtGj4j"
);

export const tokenContract = (
  tokenAddr: string,
  signer: ethers.Signer | undefined
) => contractProvider(tokenAddr, erc20Abi.abi)(signer);

export const routerContract = contractProvider(ROUTER_ADDR, routerAbi.abi);

export const factoryContract = contractProvider(
  "0xe4cC29A56C9FAeF9E15603A891804e30bA3Ac7B2",
  factoryAbi.abi
);

export const koadexContract = contractProvider(
  "0x329583373834605ee9E5adbC3BCd209Ea651df6d",
  koaladexAbi
);
