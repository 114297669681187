import { useEffect, useState } from "react";
import { Crypto } from "../models/crypto";
import { usePools } from "./usePools";

export function useTokens(routerAddr: string, tokenAddr?: string) {
  // const { loading, pools } = usePools(routerAddr);
  const { loading, pools } = usePools(routerAddr);

  const [result, setResult] = useState<Crypto[]>([]);

  useEffect(() => {
    if (loading) return;
    if (!pools.length) return;
    let tokens: Crypto[] = [];

    if (!tokenAddr) {
      const allTokens: Record<string, Crypto> = {};
      for (const item of pools) {
        allTokens[item.token0Addr] = new Crypto(
          item.token0Name,
          <></>,
          item.token0Addr,
          item.token0Symbol
        );

        allTokens[item.token1Addr] = new Crypto(
          item.token1Name,
          <></>,
          item.token1Addr,
          item.token1Symbol
        );
      }

      tokens = Object.values(allTokens);
    } else {
      // console.log(pools);
      const allTokens: Record<string, Crypto> = {};
      for (const item of pools) {
        if (item.token0Addr === tokenAddr) {
          allTokens[item.token1Addr] = new Crypto(
            item.token1Name,
            <></>,
            item.token1Addr,
            item.token1Symbol
          );

          continue;
        }

        if (item.token1Addr === tokenAddr) {
          allTokens[item.token0Addr] = new Crypto(
            item.token0Name,
            <></>,
            item.token0Addr,
            item.token0Symbol
          );

          continue;
        }
      }
      tokens = Object.values(allTokens);
    }

    setResult(tokens);
  }, [tokenAddr, loading, pools]);

  return {
    loading,
    tokens: result,
  };
}
