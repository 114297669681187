import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { INetworkModel } from "../interfaces/INetwork.model";
import { Bitcoin } from "../shared/chains/bitcoin.chain";
import { Networks } from "../shared/networks/netwoks";
import { connectXdefi } from "../states/xdefi/xdefi.signal";
import { emitAlert } from "../states/alert/alert.signal";
import { useAccount, useNetwork, useSwitchNetwork, } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export function useLocalNetwork() {
  const [switchingNetwork, setSwitchingNetwork] = useState(false);
  const [loading, setLoading] = useState(false);
  const { activateBrowserWallet } =
    useEthers();
  const { open } = useWeb3Modal()
  const { chain: chainId } = useNetwork()

  const { switchNetworkAsync } = useSwitchNetwork()
  const { address: account } = useAccount()
  const [network, setNet] = useState(0);

  useEffect(() => {
    if (!chainId) return;
    const index = Networks.findIndex((item, index) => {
      const self = item.chainId === chainId.id;
      return self;
    });

    setNet(index);
  }, [chainId]);

  const changeNetwork = async (network: INetworkModel, throwError = false) => {
    if (network.chainId === chainId?.id) return;
    if (network.chainId === Bitcoin.chainId) {
      if (!("xfi" in window)) {
        emitAlert({
          message: "لطفا کیف پول اکس دیفای را نصب کنید",
          mode: "error",
        });
        return;
      }
      connectXdefi(0);
      setNet(Networks.findIndex((item) => item.chainId === network.chainId));
      return;
    }
    if (switchingNetwork) return;
    setSwitchingNetwork(true);
    if (!account) {
      open();
    }
    if (switchNetworkAsync === undefined) return
    try {
      await switchNetworkAsync(network.chainId);
      // open();
    } catch (e) {
      if (throwError) throw new Error(`Failed to switch network`);
      return;
    } finally {
      setSwitchingNetwork(false);
    }
  };

  useEffect(() => {
    setLoading(switchingNetwork || !account);
  }, [switchingNetwork, account]);

  useEffect(() => {
    if (network === -1) {
      changeNetwork(Networks[0]).catch((e) => {
        console.log(e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  return {
    networkIndex: network,
    changeNetwork,
    networkList: Networks,
    loading,
  };
}
