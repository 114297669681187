import { useEffect } from "react";
import { LayoutsProps } from "../types/LayoutsProps.type";
import BgImg from "../../assets/image/Bg.png";

export default function RootLayout(props: LayoutsProps) {
  useEffect(() => {
    document.title = props.title || "وان دکس";
  }, [props.title]);

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${BgImg})`,
        backgroundPosition: "0 0",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="font-yekan mt-0 overflow-auto"
    >
      {props.children}
    </div>
  );
}
