import { Goerli, useConfig } from "@usedapp/core";
import { useEffect, useState } from "react";

import { from } from "rxjs";
import { onPoolsLoaded } from "../states/pools/pools.signal";
import { useGlobalState } from "./useGlobalState";
import { getPools } from "../tools/getPools";
import { CacheService } from "../tools/cacheService";
import { useLocalNetwork } from "./useNetwork";
import { calcLength } from "framer-motion";

export function usePools(routerAddr: string) {
  const { readOnlyChainId, readOnlyUrls } = useConfig();
  const [loading, setLoading] = useState(false);
  const [pools, setPools] = useState<any[]>([]);
  const {
    networkList,
    changeNetwork,
    networkIndex,
    loading: loadingNetworks,
  } = useLocalNetwork();
  const cahcedPools = useGlobalState<any[]>("pools");

  useEffect(() => {
    if (cahcedPools?.length) {
      setPools(cahcedPools);
      setLoading(false);
      return;
    }

    const cacheService = new CacheService("pools");
    const data = cacheService.readData();

    if (data !== undefined) {
      setPools(data);
      onPoolsLoaded(data);
      return;
    }

    if (!readOnlyUrls || !readOnlyChainId) return;
    // console.log(getPools(readOnlyUrls[readOnlyChainId], routerAddr));
    setLoading(true);
    const sub = from(getPools(readOnlyUrls[networkList[networkIndex]?.chainId ? networkList[networkIndex]?.chainId : readOnlyChainId], routerAddr, networkIndex, networkList)).subscribe({
      next: (response: any[]) => {
        setPools(response);
        setLoading(false);
        onPoolsLoaded(response);
        cacheService.storeData(response);
      },
      error: (e: any) => {
        console.log(e);
        setLoading(false);
      },
      complete: () => {
        setLoading(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
  }, [cahcedPools, readOnlyChainId, readOnlyUrls]);

  useEffect(() => {
    if (!readOnlyUrls || !readOnlyChainId) return;
    // console.log(getPools(readOnlyUrls[readOnlyChainId], routerAddr));
    setLoading(true);

    const cacheService = new CacheService("pools");
    const sub = from(getPools(readOnlyUrls[networkList[networkIndex]?.chainId !== -1 ? networkList[networkIndex]?.chainId : readOnlyChainId], routerAddr, networkIndex, networkList)).subscribe({
      next: (response: any[]) => {
        setPools(response);
        setLoading(false);
        onPoolsLoaded(response);
        cacheService.storeData(response);
      },
      error: (e: any) => {
        console.log(e);
        setLoading(false);
      },
      complete: () => {
        setLoading(false);
      },
    });
    return () => {
      sub.unsubscribe();
    };

  }, [networkIndex])

  return { loading, pools };
}
