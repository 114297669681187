import { routerAbi } from "../contracts/abis";
import Web3 from "web3";

export async function getRouterInfo(routerAddr: string, web3: Web3) {
  const router = new web3.eth.Contract(routerAbi, routerAddr);

  return {
    factory: await router.methods.factory().call(),
  };
}
