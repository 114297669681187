import { ICryptoModel } from "../interfaces/ICrypto.model";

export class Crypto implements ICryptoModel {
  cryptoName: string;
  icon: JSX.Element;
  address: string;
  contractAddr: string;
  symbol: string;

  constructor(
    name: string,
    icon: JSX.Element,
    address: string,
    symbol: string,
    contractAddr?: string
  ) {
    this.cryptoName = name;
    this.icon = icon;
    this.address = address;
    this.contractAddr = contractAddr || address;
    this.symbol = symbol;
  }

  name(): string {
    return this.cryptoName;
  }

  logo(): JSX.Element {
    return this.icon;
  }
}
