import { createWeb3Modal } from '@web3modal/wagmi/react'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'

import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { bscTestnet, goerli, mainnet, polygonMumbai, zetachainAthensTestnet } from 'viem/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'c21bdebe7afd553256ed6fa16d756143'


// 2. Create wagmiConfig
const { chains, publicClient } = configureChains(
    [goerli, zetachainAthensTestnet, polygonMumbai, bscTestnet],
    [walletConnectProvider({ projectId }), publicProvider()]
)

const metadata = {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
        //  @ts-ignore
        new WalletConnectConnector({ chains, options: { projectId, showQrModal: true, metadata } }),
        //  @ts-ignore
        new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    ],
    publicClient
})

// 3. Create modal
//  @ts-ignore

createWeb3Modal({ wagmiConfig, projectId, chains })

export { wagmiConfig }