import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { useRef, useState } from "react";
import { useSignal } from "../../hooks";
import { alert$ } from "./alert.signal";

export function AlertProvider(props: React.PropsWithChildren) {
  const openForEver = useRef(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    if (openForEver.current) return;
    setOpen(false);
  };
  const [time, setTime] = useState(6000);
  const [message, setMessage] = useState<string | JSX.Element>("");
  const [mode, setMode] = useState<AlertColor>("info");
  useSignal(alert$, {
    next: (v) => {
      setMessage(v.message);
      if (v.timeout) {
        if (v.timeout < 0) {
          openForEver.current = true;
        } else {
          if (v.timeout < 1000) v.timeout = 6000;
          console.log(v.timeout);
          setTime(v.timeout);
        }
      }
      if (v.mode) {
        setMode(v.mode);
      }

      setOpen(true);
    },
  });

  return (
    <>
      {props.children}
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        open={open}
        autoHideDuration={time}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={mode} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
