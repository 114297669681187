import { motion } from "framer-motion";
import { useEffect } from "react";
import Logo from "../../components/logo/logo.component";
import commonConfig from "../../shared/config/common.config";
import { IntroProps } from "./IntroProps.type";

export default function Intro(props: IntroProps) {
  useEffect(() => {
    if (props.disabled) {
      setTimeout(() => {
        if (props.onUnload) props.onUnload();
      }, 500);
    }
  }, [props, props.disabled]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.onDisabled) props.onDisabled();
    }, 2 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [props.onUnload, props]);

  return (
    <div
      className={`w-[100vw] h-[100vh] transition-all z-50 bg-white fixed top-0 left-0 flex justify-center items-center ${
        props.disabled ? "opacity-0" : "opacity-1"
      }`}>
      <div className="flex-col flex gap-y-20">
        <motion.div
          initial={{
            scale: 0,
            opacity: 0,
            y: -100,
          }}
          transition={{
            duration: 0.5,
          }}
          animate={{
            y: [-100, -100, -100, -100, -100, 0],
            scale: [0, 2, 2, 2, 2, 1],
            opacity: [0, 1, 1, 1, 1, 1],
          }}
          className="flex justify-center">
          <Logo />
        </motion.div>
        <div className="flex flex-col gap-y-5">
          <motion.div
            initial={{
              opacity: 0,
              y: -50,
            }}
            transition={{
              delay: 0.65,
              duration: 0.5,
            }}
            animate={{
              y: [-50, -50, -50, -50, -50, 0],
              opacity: [0, 0, 0, 0, 1, 1],
            }}>
            <h3 className="text-2xl font-bold text-center">
              {commonConfig.companyName}
            </h3>
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
              y: 50,
            }}
            transition={{
              delay: 0.75,
              duration: 0.5,
            }}
            animate={{
              y: [50, 50, 50, 25, 25, 0],
              opacity: [0, 0, 0.5, 0.75, 1, 1],
            }}>
            <h3 className="text-center">{commonConfig.motto}</h3>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
