import React from "react";
import { poolsContext } from "./pools/pools.context";
import { xdefiCtx } from "./xdefi/xdefi.context";
import { activeTabCTX } from "./ActiveTab/activeTab.context";
import { deadlineContext } from "./deadline/deadline.context";
export const globalStates: Record<string, React.Context<any>> = {
  pools: poolsContext,
  xdefi: xdefiCtx,
  activeTab: activeTabCTX,
  deadline: deadlineContext,
};
