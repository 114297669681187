import { useCall } from "@usedapp/core";
import { Contract, ethers } from "ethers";
import { formatEther, parseUnits } from "ethers/lib/utils";

import { routerAbi } from "../contracts/abis";
import { ICryptoModel } from "../interfaces/ICrypto.model";
import { ROUTER_ADDR } from "../shared/config/dapp.confing";
import { formatPrice } from "../tools/formatPrice";
import { from } from "rxjs";

export function useAmountOut(
  amount: number,
  tokenA?: ICryptoModel,
  tokenB?: ICryptoModel
) {
  const { error, value } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCall({
      contract: new Contract(ROUTER_ADDR, routerAbi) as any,
      method: "getAmountsOut",
      args: [
        parseUnits((amount - amount * 0.03 || 0).toString()),
        [tokenA?.contractAddr, tokenB?.contractAddr],
      ],
    }) ?? {};

  try {
    return error
      ? ethers.utils.parseUnits("0").toNumber()
      : formatPrice(formatEther(value?.amounts[1]));
  } catch {
    return "0";
  }
}
