// import LogoIcon from "../../assets/icons/logo.icon";
import LogoImg from "../../assets/image/pro.png";

export default function Logo() {
  return (
    <img
      src={LogoImg}
      alt="logo"
      width={50}
      height={50}
      className="rounded-full shadow-md"
    />
  );
}
